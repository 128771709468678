






















import { TagModel } from '@/models/post-model'
import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { SummaryViewModel } from '../../viewmodels/summary-viewmodel'

@Observer
@Component({
  components: {
    'follow-button': () => import('@/modules/common/components/follow-button.vue'),
  },
})
export default class Tag extends Vue {
  @Inject() vm!: SummaryViewModel
  @Prop() tag?: TagModel

  redirectTag(tagContent: string) {
    const searchTerm = tagContent.replaceAll('#', '')
    this.$router.push(`/search/forum?text=${searchTerm}`)
  }
}
